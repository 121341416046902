import React, { useEffect, useState } from "react";
import { getText } from "../locales";
import { Link, useNavigate } from "react-router-dom";
import { API_PATH } from "../tools/constant";
import axios from "axios";

const Footer = () => {
  const [items, setItems] = useState([]);

  const getItems = () => {
    axios.get(API_PATH + "main/tour/?popular=True").then((res) => {
      setItems(res.data);
    });
  };

  useEffect(() => {
    getItems();
  }, []);

  const nav = useNavigate();
  const link = () => {
    nav("/detail");
  };

  console.log(items);
  return (
    <>
      <div className="Footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ">
              <div className="footer_box_1">
                <img src="/img/logo.png" alt="" className="footer_logo w-50" />
                <div className="footer_icons">
                  <a href="https://www.instagram.com/fatih.tour_?igsh=Zmo2ZjRoY2Qwdmps" className="footer_a">
                    <img src="/img/icon_foot_1.png" alt="" />
                  </a>
                  <a href="" className="footer_a">
                    <img src="/img/icon_foot_2.png" alt="" />
                  </a>
                  <a href="https://t.me/fatihtour" className="footer_a">
                    <img src="/img/icon_foot_3.png" alt="" />
                  </a>
                  <a href="tel:+998978887766" className="footer_a">
                    <img src="/img/icon_foot_4.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="footer_nav_box">
                <div className="footer_box_name">{getText("tour")}</div>
                {items &&
                  items.slice(0, 3).map((item, index) => {
                    return (
                      <div
                        onClick={() => nav(`/detail/${item.id}`)}
                        key={index}
                        className="link_footer mt-1"
                      >
                        {item.location}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-2 col-4 d-lg-flex d-none">
              <div className="footer_nav_box">
                <div className="footer_box_name">{getText("tour")}</div>
                {items &&
                  items.slice(4, 7).map((item, index) => {
                    return (
                      <div
                        onClick={() => nav(`/detail/${item.id}`)}
                        key={index}
                        className="link_footer mt-1"
                      >
                        {item.location}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-2 col-4 d-lg-flex d-none">
              <div className="footer_nav_box">
                <div className="footer_box_name">{getText("tour")}</div>
                {items &&
                  items.slice(8, 11).map((item, index) => {
                    return (
                      <div
                        onClick={() => nav(`/detail/${item.id}`)}
                        key={index}
                        className="link_footer mt-1"
                      >
                        {item.location}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="footer_nav_box">
                <div className="footer_box_name">
                  {getText("footer_box_name")}
                </div>
                <Link to="/" className="link_footer mt-1">
                  {getText("nav_1")}
                </Link>
                <Link to="/offers" className="link_footer">
                  {getText("nav_2")}
                </Link>
                <Link to="/about" className="link_footer">
                  {getText("nav_3")}
                </Link>
                <Link to="/contact" className="link_footer">
                  {getText("nav_4")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
